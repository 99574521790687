import Head from "next/head";
import Button from "../components/button";

export default function NotFound() {
  return (
    <>
      <Head>
        <title>404</title>
      </Head>
      <section className="pt-100 pb-100">
        <div className="container pt-50 pt-md-100">
          <div className="frame frame--justify-md-center">
            <div className="bit-xxs-12 bit-md-8 bit-lg-6">
              <h1 className="h2">Niets gevonden (404)</h1>
              <p className="pb-20">
                De pagina die u probeert te bereiken bestaat niet of is
                verplaatst. Tikte je zelf het webadres in? Mogelijk staat er een
                foutje in. Corrigeer het en probeer opnieuw.
              </p>

              <Button
                button={{ title: "Ga naar de homepage", url: "/" }}
                classes="button"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
